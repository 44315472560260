import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import Link from './link'

import style from './breadcrumb.module.css'


const Breadcrumb = ({ trail }) => {
  const data = useStaticQuery(graphql`
    query BreadcrumbQuery {
      ...SiteInfo
    }
  `)

  const { links } = data.site.siteMetadata
  const first = [{
    name: 'Home',
    slug: links.home,
  }]

  return (
    <div className={style.breadcrumb}>
      {first.concat(trail).map((crumb, index) => {
        if (crumb !== trail[trail.length - 1]) {
          return (
            <span key={`breadcrumb-${index}`}>
              <Link href={crumb.slug}>
                {crumb.name}
              </Link>
              <span className={style.separator}>›</span>
            </span>
          )
        }
        else return (
          <span key={`breadcrumb-${index}`}>{crumb.name}</span>
        )
      })}
    </div>
  )
}
Breadcrumb.propTypes = {
  trail: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Breadcrumb
